import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetEstimate } from '~/hooks/estimates';

import styles from './various.module.scss';
import { IVarious } from '~/types/estimates';
import VariousForm from '~/components/estimate/form/VariousForm';

const Various = ({ isPreview }: { isPreview: boolean }) => {
  const { id, variousId } = useParams();

  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const various = useMemo(() => {
    const found = estimate?.various.find((d: IVarious) => d._id === variousId);
    return found;
  }, [estimate, variousId]);

  return (
    <div className={styles.phase}>
      <div className={styles.content}>
        {(id && various) && (
          <VariousForm
            key={`various-${various._id}`}
            estimateId={id}
            various={various}
            isPreview={isPreview}
          />
        )}
      </div>
    </div>

  );
};

export default Various;
