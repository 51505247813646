import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './elevator-form.module.scss';

import {
  InputNumber,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';

const ElevatorForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const phase = watch();
  return (
    <div id="elevator" className={styles.elevator}>
      <h4>Ascenseur</h4>
      <h5>Transport</h5>
      <div className={`${styles.row}`}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
                  label='Transport aller'
                value={phase?.elevator?.transport?.go ? `${phase?.elevator?.transport?.go} €` : '---'}
            />
          : <InputNumber
            name='elevator.transport.go'
            label='Transport aller'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Transport retour'
              value={phase?.elevator?.transport?.back ? `${phase?.elevator?.transport?.back} €` : '---'}
            />
          : <InputNumber
            name='elevator.transport.back'
            label='Transport retour'
            control={control}
          />
        }
        </div>
      </div>
      <h5>Montage/Démontage</h5>
      <div className={`${styles.row}`}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Montage'
              value={phase?.elevator?.assembly ? `${phase?.elevator?.assembly} €` : '---'}
            />
          : <InputNumber
            name='elevator.assembly'
            label='Montage'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
                label='Montage'
                value={phase?.elevator?.disassembly ? `${phase?.elevator?.disassembly} €` : '---'}
              />
            : <InputNumber
              name='elevator.disassembly'
              label='Démontage'
              control={control}
            />
          }
        </div>
      </div>
      <h5>Location</h5>
      <div className={`${styles.row}`}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
                label='Location'
                value={phase?.elevator?.rental ? `${phase?.elevator?.rental} €` : '---'}
              />
          : <InputNumber
            name='elevator.rental'
            label='Location'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Durée'
              value={phase?.elevator?.duration ? `${phase?.elevator?.duration} €` : '---'}
            />
          : <InputNumber
            name='elevator.duration'
            label='Durée'
            control={control}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default ElevatorForm;
