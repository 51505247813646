import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './thermalTarping-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import { useGetEstimatesList } from '~/hooks/estimates';
import PreviewField from '~/components/PreviewField';

const ThermalTarpingForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const phase = watch();
  const { data: list = { discount: {} } } = useGetEstimatesList();
  const {
    thermalTarping,
  } = list.discount;

  return (
    <div id="thermalTarping" className={styles.thermalTarping}>
      <h4>Thermobachage</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Surface'
              value={phase?.thermalTarping?.surface ? `${phase?.thermalTarping?.surface} m²` : '---'}
            />
          : <InputNumber
            name='thermalTarping.surface'
            label='Surface'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
               label='Remise sur Thermo'
              value={phase?.thermalTarping?.discount ? `${phase.thermalTarping.discount * 100} %` : '---'}
            />
          : <StyledSelect
            label='Remise sur Thermo'
            name='thermalTarping.discount'
            control={control}
            options={thermalTarping || []}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default ThermalTarpingForm;
