import axios from 'axios';

const getNotifications = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/notif`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.notifications;
};

const deleteNotification = async ({
  id,
} : {
  id: string,
})
: Promise<{ message: string }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/notif/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  deleteNotification,
  getNotifications,
};
