import React from 'react';
import {
  Controller, useFieldArray, useFormContext,
} from 'react-hook-form';
import { IoTrash } from 'react-icons/io5';
import styles from './customs-form.module.scss';
import PreviewField from '~/components/PreviewField';

const CustomsForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  console.log(control);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customs',
  });

  return (
    <div id="customs" className={styles.customs}>
      <div className={styles.list}>
      {fields.map((f: any, index: number) => (
        <div key={f.id} className={styles.custom}>
          <hr />
          <div className={`${styles.row} ${styles.name}`}>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                  label='Désignation'
                  value={watch(`customs.${index}.label`) || '---'}
                />
                : <>
                <label>Désignation</label>
                <Controller
                  name={`customs.${index}.label`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Libellé"
                    />
                  )}
                />
              </>
            }
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label="Prix unitaire"
                  value={watch(`customs.${index}.unitPrice`) ? `${watch(`customs.${index}.unitPrice`)} €` : '---'}
                />
              : <>
                <label>PU net</label>
                <Controller
                  name={`customs.${index}.unitPrice`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Prix unitaire"
                      type="number"
                    />
                  )}
                />
              </>
              }
            </div>
            <div className={styles.containerField }>
            {isPreview
              ? <PreviewField
                  label="Quantité"
                  value={watch(`customs.${index}.quantity`) || '---'}
                />
              : <>
                <label>Quantité</label>
                <Controller
                  name={`customs.${index}.quantity`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Quantité"
                      type="number"
                    />
                  )}
                />
              </>
              }
            </div>
          </div>
          {!isPreview
            && <button type="button" className={styles.remove} onClick={() => remove(index)}>
                Supprimer <IoTrash />
              </button>
            }
        </div>
      ))}
      </div>
      {!isPreview
        && <button
          type="button"
          className={styles.add}
          onClick={() => append({ label: '', quantity: null, unitPrice: null })}
        >
          Ajouter un objet
        </button>
      }
    </div>
  );
};

export default CustomsForm;
