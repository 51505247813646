import React, { ChangeEvent, useRef } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { LuPaperclip } from 'react-icons/lu';
import { MdDeleteOutline } from 'react-icons/md';
import { format } from 'date-fns';
import styles from './comments.module.scss';
import { WorksiteComment } from '~/types/worksites';
import { ErrorField } from '~/lib/HooksFormFields';
import { useAddWorksiteComment } from '~/hooks/worksites';
import { useUserProfile } from '~/hooks/auth';
import { downloadFile } from '~/utils';

const CommentForm = ({ worksiteId }: { worksiteId: string }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {
    mutate: addWorksiteComment,
  } = useAddWorksiteComment();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: {
      errors,
    },
  } = useForm<FieldValues>({
    defaultValues: {
      text: '',
      file: null,
    },
  });

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const onSubmit = async (data: FieldValues) => {
    await addWorksiteComment({ ...data, worksiteId });
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
    reset({
      text: '',
      file: null,
    });
  };

  return (
    <div className={styles.form}>
      <div className={styles.input}>
        <Controller
          name="text"
          control={control}
          rules={{ required: 'le message est requis' }}
          render={({ field }) => (
            <textarea
              {...field}
              ref={textareaRef}
              onInput={handleInput}
              style={{ overflow: 'hidden', resize: 'none' }}
              placeholder="Tapez votre commentaire"
            />
          )}
        />
        {errors?.text?.message && <ErrorField message={errors?.text?.message} />}
        {watch('file')?.name && <p>{watch('file')?.name} <span onClick={() => setValue('file', null)}><MdDeleteOutline size={22} /></span></p>}
      </div>
      <Controller
        name="file"
        control={control}
        render={({ field }) => (
          <>
            <input
              type="file"
              id="comment-file"
              className={styles.file}
              onChange={
                (e: ChangeEvent<HTMLInputElement>) => {
                  const file = e?.target?.files && e?.target?.files[0];
                  if (file) {
                    field.onChange(file);
                  }
                }
              }
            />
          </>
        )}
      />
      <div className={styles.actions}>
        <label htmlFor='comment-file'>
          <LuPaperclip size={18} />
        </label>
        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          Envoyer
        </button>
      </div>
    </div>
  );
};

const Comment = ({ comment }: { comment: WorksiteComment }) => {
  const { data: userAuth } = useUserProfile();
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const isMe = userAuth?._id === comment?.author?._id;
  return (
    <div className={`${styles.comment} ${isMe ? styles.me : ''}`}>
      <div className={styles.author}>
        {!isMe && <div className={styles.profile}>
        {comment?.author?.profile.firstName[0]}
        {comment?.author?.profile.lastName[0]}
        </div>}
        <p>{isMe ? 'Moi' : <>{comment?.author?.profile.firstName} {comment?.author?.profile.lastName}</>}</p><p className={styles.date}>{comment?.createdAt && format(new Date(comment?.createdAt), 'dd.MM.yyyy')}</p>
      </div>
      <p className={styles.text}>{comment?.text}</p>
      {comment?.file?.name
        && <div className={styles.file}>
          <a
            onClick={() => downloadFile(`${process.env.REACT_APP_API_URL}/files/public/${comment.file.path}?token=${token}`, comment.file.name)}
            download
          >
            <LuPaperclip />
            {comment?.file?.name}
          </a>
        </div>
      }
    </div>
  );
};

export default function Comments({
  comments = [],
  worksiteId,
} : {
  comments: WorksiteComment[],
  worksiteId : string
}) {
  console.log(comments, worksiteId);
  return (
    <div className={styles['comments-container']}>
      <h4>Commentaires</h4>
      <div className={styles.comments}>
        {comments.map((c:WorksiteComment) => <Comment
          key={c._id}
          comment={c}
        />)}
      </div>
      <CommentForm
        worksiteId={worksiteId}
      />
    </div>
  );
}
