import React, { useEffect } from 'react';
import { BsCheck } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import styles from './create-worksite.module.scss';

import {
  StyledSelect,
} from '~/lib/HooksFormFields';
import { useCreateWorksite, useGetWorksitesList } from '~/hooks/worksites';
import { IOption } from '~/types/options';

const CreateWorksite = ({
  onSuccess,
  clientId,
} : {
  clientId?: string,
  onSuccess: (estimateId: string) => void,
}) => {
  const {
    control, handleSubmit, watch, setValue,
  } = useForm({
    defaultValues: {
      client: clientId || null,
      estimate: null,
    },
  });
  const { data: list = {} } = useGetWorksitesList();
  const form = watch();
  const {
    isLoading,
    mutateAsync: createWorksite,
  } = useCreateWorksite();

  const {
    estimates = [],
    clients = [],
  } = list;

  const submit = async (data: any) => {
    try {
      const { worksite } = await createWorksite(data);
      onSuccess(worksite._id);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!form?.estimate) return;
    const client = estimates.find((e: IOption) => e.value === form.estimate)?.client;
    if (client) {
      setValue('client', client);
    }
  }, [form?.estimate]);

  return (
    <div className={styles.form}>
      <h3>Créer un chantier</h3>
      <div className={styles.fields}>
        <div className={styles.containerField}>
          <StyledSelect
            name='estimate'
            label='Choisir un devis'
            control={control}
            options={estimates}
            isSearchable
          />
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            name='client'
            label='Choisir un client'
            control={control}
            options={clients}
            isSearchable
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <button className='invisible'>Annuler</button>
        <button disabled={isLoading} onClick={handleSubmit(submit)}>
          <span className='icon'><BsCheck /></span>
          <span>Valider</span>
        </button>
      </div>
    </div>
  );
};

export default CreateWorksite;
