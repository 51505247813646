import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './study-form.module.scss';

import {
  StyledSelect,
} from '~/lib/HooksFormFields';
import { useGetEstimatesList } from '~/hooks/estimates';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';

const StudyForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const { data: list = { studies: [] } } = useGetEstimatesList();
  const phase = watch();

  return (
    <div id="study" className={styles.study}>
      <h4>Frais d’études</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Frais d’études'
              value={list?.studies?.find((a:IOption) => a.value === phase?.study)?.label || '---'}
            />
          : <StyledSelect
            label='Frais d’études'
            name='study'
            control={control}
            options={list?.studies || []}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default StudyForm;
