import React, { useEffect, useCallback } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import styles from './client-form.module.scss';
import { IClient } from '~/types/clients';
import {
  InputText,
  InputEmail,
  InputFormatPattern,
  ErrorField,
} from '~/lib/HooksFormFields';
import { useUpdateClient } from '~/hooks/clients';

const ClientForm = ({ client } : { client: IClient }) => {
  const {
    mutate: updateClient,
    // isLoading: isPutLoading,
  } = useUpdateClient('Les modifications ont été prise en compte');

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: client,
  });
  const errorsForm: any = errors;

  const submit = useCallback(async (data: FieldValues) => {
    const dataUser: FieldValues = {
      _id: client._id,
      ...data,
    };
    await updateClient(dataUser);
  }, [client]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  // side effect on debouncedSubmit to trigger submit on change for existing user
  useEffect(() => {
    if (!debouncedSubmit) return () => {};
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [debouncedSubmit, submitOnChange, client]);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <section>
          <h4>Informations</h4>
          <div className={styles.containerField}>
            <InputText
              label="Adresse"
              name='address'
              control={control}
            />
          </div>
        </section>
        <section>
          <h4>Contact</h4>
          <div className={styles.row}>
            <div className={styles.containerField}>
              <InputText
                label="Prénom"
                name='contact.firstName'
                control={control}
              />
            </div>
            <div className={styles.containerField}>
              <InputText
                label="Nom"
                name='contact.lastName'
                control={control}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.containerField}>
              <InputFormatPattern
                name="contact.phone"
                label="Téléphone"
                control={control}
                format="## ## ## ## ##"
                rules={{
                  pattern: {
                    value: /^(?:\s*\d){10}$/,
                    message: "Le champs de numéro de téléphone n'est pas valide",
                  },
                }}
              />
              {errorsForm?.phone?.message && (
                <ErrorField message={errorsForm.phone.message} />
              )}
            </div>
            <div className={styles.containerField}>
             <InputEmail
                name="contact.email"
                control={control}
                label="Adresse mail"
                placeholder='adressemail@mail.fr'
              />
              {errorsForm.email && <ErrorField message={errorsForm.email.message} />}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ClientForm;
