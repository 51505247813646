import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './verification-form.module.scss';

import {
  Checkbox,
} from '~/lib/HooksFormFields';

const VerificationForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control } = useFormContext();

  return (
    <div id="verification" className={styles.verification}>
      <h4>Vérification</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='verification.isReception'
            label='Vérification de Réception'
            control={control}
            disabled={isPreview}
          />
        </div>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='verification.isQuarterly'
            label='Vérification Trimestrielle'
            control={control}
            disabled={isPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default VerificationForm;
