import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './winches-form.module.scss';

import {
  InputNumber,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';

const WinchesForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const various = watch();
  return (
    <div id="winches" className={styles.winches}>
      <h4>Treuils</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='150kg/200 kg'
              value={various?.winches?.winch20O ? `${various?.winches?.winch20O}` : '---'}
            />
          : <InputNumber
            name='winches.winch20O'
            label='150kg/200 kg'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
              label='500 kg'
              value={various?.winches?.winch50O ? `${various?.winches?.winch50O}` : '---'}
            />
            : <InputNumber
            name='winches.winch50O'
            label='500 kg'
            control={control}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default WinchesForm;
