import React, { useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IoCheckmark } from 'react-icons/io5';
import styles from './phase-sidebar.module.scss';
import { IPhase } from '~/types/estimates';
import { useGetEstimate } from '~/hooks/estimates';

type LinkId = {
  value: string,
  label: string,
};

export const phasesMenu = [
  {
    label: 'Dimension',
    value: '#dimension',
  },
  {
    label: 'Montage/Démontage',
    value: '#montage',
  },
  {
    label: 'Location',
    value: '#location',
  },
  {
    label: 'Transport',
    value: '#transport',
  },
  {
    label: 'Manutention',
    value: '#manutention',
  },
  {
    label: 'Chariot',
    value: '#cart',
  },
  {
    label: 'Vérification',
    value: '#verification',
  },
  {
    label: 'Filets',
    value: '#nets',
  },
  {
    label: 'Thermobachage',
    value: '#thermalTarping',
  },
  {
    label: 'Protection de Toit',
    value: '#roofProtection',
  },
  {
    label: 'Bardage',
    value: '#cladding',
  },
  {
    label: 'Fourniture Pose de Planches',
    value: '#board',
  },
  {
    label: 'Ascenseur',
    value: '#elevator',
  },
  {
    label: 'Frais d’études',
    value: '#study',
  },
];

const PhaseSidebar = () => {
  const location = useLocation();
  const { id, phaseId, ...params } = useParams();

  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const getPhaseValidation = useCallback((type: string) => {
    let isValid = false;
    const phase = estimate?.phases.find((d: IPhase) => d._id === phaseId || params['*']);
    console.log(phase);
    if (type === '#dimension' && phase?.dimension?.tonnage) {
      isValid = true;
    }
    if (type === '#montage' && phase?.mobilization?.type) {
      isValid = true;
    }
    if (type === '#location' && phase?.rental?.duration) {
      isValid = true;
    }
    if (type === '#transport' && phase?.transport?.type && phase?.transport?.type !== 'Aucun') {
      isValid = true;
    }
    if (type === '#manutention' && phase?.handling?.type && phase?.handling?.type !== 'Aucun') {
      isValid = true;
    }
    if (type === '#cart' && phase?.cart.isCart) {
      isValid = true;
    }
    if (type === '#verification' && (phase?.verification.isReception || phase?.verification?.isQuarterly)) {
      isValid = true;
    }
    if (type === '#nets' && (phase?.nets.isNets || phase?.nets.isTarpaulins || phase?.nets.isWovenFabrics)) {
      isValid = true;
    }
    if (type === '#thermalTarping' && phase?.thermalTarping?.surface) {
      isValid = true;
    }
    if (type === '#cladding' && phase?.cladding?.isCladding) {
      isValid = true;
    }
    if (type === '#board' && phase?.boards.countLaying && phase?.boards.width) {
      isValid = true;
    }
    if (type === '#elevator' && phase?.elevator?.duration && phase?.elevator?.rental) {
      isValid = true;
    }
    if (type === '#study' && phase?.study) {
      isValid = true;
    }
    return isValid;
  }, [estimate, phaseId, params]);

  return (
  <div className={styles.sidebar}>
    <ul>
      {phasesMenu.map((link: LinkId) => <li
        key={link.value}
        className={location.hash === link.value ? styles.active : ''}
      >
        <Link to={`${link.value}`}>
          {getPhaseValidation(link.value) ? <IoCheckmark color="#6AA187" size={18} /> : null}
          <p>{link.label}</p>
        </Link>
      </li>)}
    </ul>
  </div>
  );
};

export default PhaseSidebar;
