import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  addWorksiteComment,
  addWorksiteFile,
  addWorksiteNote,
  createWorksite,
  deleteWorksiteFile,
  deleteWorksiteNote,
  getWorksite,
  getWorksites,
  getWorksitesList,
  updateWorksite,
  updateWorksiteNote,
} from '~/api/worksites';
import { INote } from '~/types/clients';
import { ArgsGetWorksites, IWorksite, WorksiteComment } from '~/types/worksites';

const useGetWorksites = (params: ArgsGetWorksites) => useQuery({
  queryKey: ['worksites', params],
  queryFn: async (): Promise<IWorksite[]> => getWorksites(params),
  keepPreviousData: true,
});

const useGetWorksitesList = () => useQuery({
  queryKey: ['worksite-list'],
  queryFn: async (): Promise<any | null> => getWorksitesList(),
  keepPreviousData: true,
});

const useCreateWorksite = (successMessage?: string) => {
  const queryworksites = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IWorksite>) => createWorksite(data),
    onSuccess: async ({ worksite }) => {
      queryworksites.invalidateQueries({ queryKey: ['worksites'] });
      queryworksites.invalidateQueries({
        queryKey: ['worksites'],
        exact: false,
      });
      queryworksites.setQueryData(['worksites', worksite._id], worksite);
      if (successMessage) toast(successMessage);
    },
  });
};

const useGetWorksite = (id: string) => useQuery({
  queryKey: ['worksite', id],
  queryFn: async (): Promise<IWorksite | null> => getWorksite(id),
  keepPreviousData: false,
});

const useUpdateWorksite = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IWorksite>) => updateWorksite(data),
    onSuccess: async ({ worksite }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['worksites'],
        exact: false,
      });
      queryEstimate.setQueryData(['worksite', worksite._id], worksite);
      if (successMessage) toast(successMessage);
    },
  });
};

const useAddWorksiteFile = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<any>) => addWorksiteFile(data),
    onSuccess: async ({ worksite }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['worksites'],
        exact: false,
      });
      queryEstimate.setQueryData(['worksite', worksite._id], worksite);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDeleteWorksiteFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { fileId: string, worksiteId: string }) => deleteWorksiteFile(data),
    onSuccess: async ({ worksite }) => {
      queryClient.invalidateQueries({
        queryKey: ['worksites'],
        exact: false,
      });
      queryClient.setQueryData(['worksite', worksite._id], worksite);
    },
  });
};

const useAddWorksiteNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => addWorksiteNote(data),
    onSuccess: async ({ worksite }) => {
      queryClient.setQueryData(['worksite', worksite._id], worksite);
      if (successMessage) toast(successMessage);
    },
  });
};

const useAddWorksiteComment = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (
      data: Partial<WorksiteComment> & { worksiteId: string },
    ) => addWorksiteComment(data),
    onSuccess: async ({ worksite }) => {
      queryClient.setQueryData(['worksite', worksite._id], worksite);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateWorksiteNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => updateWorksiteNote(data),
    onSuccess: async ({ worksite }) => {
      queryClient.setQueryData(['worksite', worksite._id], worksite);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDeleteWorksiteNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { noteId: string, worksiteId: string }) => deleteWorksiteNote(data),
    onSuccess: async ({ worksite }) => {
      queryClient.setQueryData(['worksite', worksite._id], worksite);
      if (successMessage) toast(successMessage);
    },
  });
};

export {
  useGetWorksites,
  useGetWorksitesList,
  useAddWorksiteFile,
  useDeleteWorksiteFile,
  useCreateWorksite,
  useGetWorksite,
  useUpdateWorksite,
  useAddWorksiteNote,
  useUpdateWorksiteNote,
  useDeleteWorksiteNote,
  useAddWorksiteComment,
};
