import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetEstimatesList } from '~/hooks/estimates';
import styles from './transport-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import { IOption } from '~/types/options';
import PreviewField from '~/components/PreviewField';

const TransportForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const phase = watch();
  const type = watch('transport.type');
  const { data: list = {} } = useGetEstimatesList();

  return (
    <div id="transport" className={styles.transport}>
      <h4>Transport</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
              label='Type de trajets'
              value={list?.transport?.transportTypes?.find((o:IOption) => o.value === phase?.transport?.type)?.label || '---'}
            />
          : <StyledSelect
            label='Type de trajets'
            name='transport.type'
            control={control}
            options={list?.transport?.transportTypes}
            handleChange={(opt) => {
              if (opt.value !== 'Mode manuel') {
                setValue('transport.custom.go', null);
                setValue('transport.custom.back', null);
              }
            }}
          />
          }
        </div>
      </div>
      {type === 'Mode manuel'
       && <>
          <h5>Transport 6m</h5>
          <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Aller'
              value={phase?.transport?.custom?.truck6m?.go || '---'}
            />
            : <InputNumber
              name='transport.custom.truck6m.go'
              label='Aller'
              control={control}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Retour'
              value={phase?.transport?.custom?.truck6m?.back || '---'}
            />
            : <InputNumber
              name='transport.custom.truck6m.back'
              label='Retour'
              control={control}
            />
          }
          </div>
        </div>
        <h5>Transport 12m</h5>
          <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                label='Aller'
                value={phase?.transport?.custom?.truck12m?.back || '---'}
              />
              : <InputNumber
                name='transport.custom.truck12m.go'
                label='Aller'
                control={control}
              />
            }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Retour'
                value={phase?.transport?.custom?.truck12m?.back || '---'}
              />
            : <InputNumber
              name='transport.custom.truck12m.back'
              label='Retour'
              control={control}
            />
          }
          </div>
        </div>
      </>
      }
    </div>
  );
};

export default TransportForm;
