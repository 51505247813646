import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import styles from './create-phase.module.scss';

import {
  ErrorField,
  StyledSelect,
} from '~/lib/HooksFormFields';

import { IPhase } from '~/types/estimates';
import { IWorksite, WorksitePhase } from '~/types/worksites';
import { useUpdateWorksite } from '~/hooks/worksites';

const CreatePhase = ({
  worksite,
  onSuccess,
  close,
} : {
  worksite?: IWorksite | null,
  close: () => void,
  onSuccess: (phase: WorksitePhase) => void,
}) => {
  const {
    isLoading,
    mutateAsync: updateWorksite,
  } = useUpdateWorksite();

  const {
    control, handleSubmit,
    formState: { errors },
  } = useForm();

  const phasesOptions = worksite?.estimate?.phases?.map(
    (p: IPhase) => ({ label: p.name, value: p.name }),
  ) || [];

  const submit = async (data: any) => {
    try {
      const phases = worksite?.phases ? [...worksite.phases.map(
        (p) => ({
          ...p,
          fitters: p.fitters.map(
            (f) => ({ ...f, user: f.user?._id }),
          ),
        }),
      )] : [];
      phases.push(data);
      const payload : any = {
        _id: worksite?._id,
        phases,
      };
      const { worksite: worksiteUpdated } = await updateWorksite(payload);
      const created = worksiteUpdated.phases.length
        && worksiteUpdated.phases[worksiteUpdated.phases.length - 1];
      if (created) {
        onSuccess(created);
      }
      close();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.form}>
      <h3>Ajouter une phase</h3>
      <div className={styles.fields}>
        <div className={styles.containerField}>
          <StyledSelect
            name='name'
            label='Choisir une phase du devis ou créer une nouvelle phase'
            control={control}
            options={phasesOptions}
            isCreatable
            rules={{
              required: 'La phase est requise',
            }}
            isSearchable
          />
          {errors?.name?.message && <ErrorField message={errors?.name?.message}/>}
        </div>
      </div>
      <div className={styles.buttons}>
        <button className='invisible'
          onClick={() => close()}
        >Annuler</button>
        <button disabled={isLoading} onClick={handleSubmit(submit)}>
          <span className='icon'><BsCheck /></span>
          <span>Valider</span>
        </button>
      </div>
    </div>
  );
};

export default CreatePhase;
