import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetEstimatesList } from '~/hooks/estimates';
import styles from './rental-form.module.scss';

import {
  Checkbox,
  StyledSelect,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';

const RentalForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const phase = watch();
  const { data: list = { rental: {} } } = useGetEstimatesList();
  const {
    durations,
  } = list.rental;

  return (
    <div id="location" className={styles.rental}>
      <h4>Location</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Durée'
              value={phase?.rental?.duration ? `${phase.rental.duration} mois` : '---'}
            />
            : <StyledSelect
            label='Durée'
            name='rental.duration'
            control={control}
            options={durations}
          />
          }
        </div>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='rental.globalPrice'
            label='Appliquer un tarif global'
            control={control}
            disabled={isPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default RentalForm;
