import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './floorProtection-form.module.scss';

import {
  InputNumber,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';

const FloorProtectionForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const various = watch();
  return (
    <div id="floorProtection" className={styles.floorProtection}>
      <h4>Protection Sol<span> (Remopla compris geotextile)</span></h4>
      <div className={styles.row}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Surface en m²'
              value={various?.floorSurface ? `${various?.floorSurface} m²` : '---'}
            />
          : <InputNumber
            name='floorSurface'
            label='Surface en m²'
            control={control}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default FloorProtectionForm;
