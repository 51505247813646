import React, { useEffect } from 'react';
import { BsCheck } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { IoTrash } from 'react-icons/io5';
import styles from './fitter-modal.module.scss';

import {
  ErrorField,
  InputText,
  StyledSelect,
} from '~/lib/HooksFormFields';

import { IWorksite, WorksiteFitter } from '~/types/worksites';
import { useGetWorksitesList, useUpdateWorksite } from '~/hooks/worksites';

const FitterModal = ({
  worksite,
  fitter,
  phaseId,
  close,
} : {
  worksite?: IWorksite | null,
  fitter?: WorksiteFitter | null,
  phaseId: string,
  close: () => void,
}) => {
  const {
    isLoading,
    mutateAsync: updateWorksite,
  } = useUpdateWorksite();

  const {
    data: list,
  } = useGetWorksitesList();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const fitterOptions = list?.fittersUsers || [];

  const submit = async (data: any) => {
    try {
      const phases = worksite?.phases ? [...worksite.phases.map(
        (p) => ({
          ...p,
          fitters: p.fitters.map(
            (f) => ({ ...f, user: f.user?._id }),
          ),
        }),
      )] : [];

      const phaseIndex = phases.findIndex(
        (p: any) => p._id === phaseId,
      );

      if (phaseIndex !== -1) {
        if (!data?._id) {
          phases[phaseIndex].fitters.push(data);
        }
        const fitterIndex = phases[phaseIndex].fitters?.findIndex(
          (f: any) => f._id === data?._id,
        );
        if (fitterIndex !== -1) {
          phases[phaseIndex].fitters[fitterIndex] = data;
        }
      }

      const payload : any = {
        _id: worksite?._id,
        phases,
      };
      console.log(data);
      await updateWorksite(payload);
      close();
    } catch (e) {
      console.log(e);
    }
  };

  const deleteFitter = async () => {
    try {
      const phases = worksite?.phases ? [...worksite.phases.map(
        (p) => ({
          ...p,
          fitters: p.fitters.map(
            (f) => ({ ...f, user: f.user?._id }),
          ),
        }),
      )] : [];

      const phaseIndex = phases.findIndex(
        (p: any) => p._id === phaseId,
      );

      if (phaseIndex !== -1) {
        if (fitter?._id) {
          phases[phaseIndex].fitters = phases[phaseIndex].fitters.filter(
            (f: any) => f._id !== fitter?._id,
          );
        }
      }

      const payload : any = {
        _id: worksite?._id,
        phases,
      };

      await updateWorksite(payload);
      close();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (fitter) {
      const resetFitter = {
        ...fitter,
        startDate: fitter?.startDate ? format(new Date(fitter.startDate), 'yyyy-MM-dd') : null,
        endDate: fitter?.endDate ? format(new Date(fitter.endDate), 'yyyy-MM-dd') : null,
        user: fitter.user?._id,
      };
      reset(resetFitter);
    }
  }, [fitter]);

  return (
    <div className={styles.form}>
      <h3>{fitter?._id ? 'Modifier le monteur' : 'Ajouter une monteur'}</h3>
      <div className={styles.fields}>
        <div className={styles.containerField}>
          <StyledSelect
            name='user'
            label='Choisir un monteur'
            control={control}
            options={fitterOptions}
            rules={{
              required: 'Le monteur est requis',
            }}
            isSearchable
          />
          {errors?.user?.message && <ErrorField message={errors?.user?.message}/>}
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              type="date"
              label="Date de début"
              name="startDate"
              control={control}
              rules={{
                required: 'La date de début est requise',
              }}
            />
            {errors?.startDate?.message && <ErrorField message={errors?.startDate?.message}/>}
          </div>
          <div className={styles.containerField}>
            <InputText
              type="date"
              label="Date de fin"
              name="endDate"
              control={control}
              rules={{
                required: 'La date de fin est requise',
              }}
            />
            {errors?.endDate?.message && <ErrorField message={errors?.endDate?.message}/>}
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        {fitter?._id
          ? <button className={styles.delete}
            onClick={() => deleteFitter()}
          >
            <span className='icon'><IoTrash /></span>
            <span>Supprimer le monteur</span>
          </button>
          : <span></span>}
        <div className={styles.actions}>
          <button
            className='invisible'
            onClick={() => close()}
          >
            Annuler
          </button>
          <button disabled={isLoading} onClick={handleSubmit(submit)}>
            <span className='icon'><BsCheck /></span>
            <span>Valider</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FitterModal;
