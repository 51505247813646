import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './cladding-form.module.scss';

import {
  Checkbox,
  InputNumber,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';

const CladdingForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const phase = watch();
  const isCladding = watch('cladding.isCladding');

  useEffect(() => {
    if (!isCladding) {
      setValue('cladding.width', null);
      setValue('cladding.height', null);
    }
  }, [isCladding]);
  return (
    <div id="cladding" className={styles.cladding}>
      <h4>Bardage</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='cladding.isCladding'
            label='Avec bardage'
            control={control}
            disabled={isPreview}
          />
        </div>
      </div>

        {isCladding
        && <div className={styles.row}>
          <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
              label='Longueur'
              value={phase?.cladding?.width ? `${phase?.cladding?.width} m` : '---'}
            />
            : <InputNumber
              name='cladding.width'
              label='Longueur'
              max={100}
              control={control}
            />
          }
          </div>
          <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
              label='Hauteur'
              value={phase?.cladding?.height ? `${phase?.cladding?.height} m` : '---'}
            />
            : <InputNumber
              name='cladding.height'
              label='Hauteur'
              max={100}
              control={control}
            />
          }
          </div>
        </div>
        }
    </div>
  );
};

export default CladdingForm;
