import React, { useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { IoMdAdd } from 'react-icons/io';
import Modal from '~/lib/Modal';

import styles from './worksites.module.scss';
import FilterSearchParam from '~/components/FilterSearchParam';
import InputSearch from '~/components/InputSearch';

import { useGetWorksites, useGetWorksitesList } from '~/hooks/worksites';
import WorksitesTable from '~/components/table/WorksitesTable';
import CreateWorksite from '~/components/worksite/form/CreateWorksite';

const Worksites = () => {
  const modalRef = useRef<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const navigate = useNavigate();
  const {
    data: worksites = [],
  } = useGetWorksites({
    ...paramsObject,
    search: debouncedSearch,
  });

  const { data: list = {} } = useGetWorksitesList();
  const { agencies = [], departments = [] } = list;

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <>
      <Modal width={450} ref={modalRef}>
        <CreateWorksite onSuccess={(worksiteId: string) => {
          modalRef.current.close();
          navigate(`/worksites/${worksiteId}`);
        }} />
      </Modal>
      <div className={styles.worksites}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1>Les chantiers</h1>
          </div>
          <div className={styles.filters}>
            <div className={styles.selects}>
              <div className={styles.field}>
                <FilterSearchParam
                  label='Agence'
                  name='agency'
                  options={agencies}
                  feminine
                />
              </div>
              <div className={styles.field}>
                <FilterSearchParam
                  label='Département'
                  name='department'
                  options={departments}
                />
              </div>
            </div>
            <div className={styles.search}>
              <InputSearch
                defaultValue={search}
                handleSearch={handleSearch}
                label='Rechercher'
              />
            </div>
            <button onClick={() => modalRef.current.open()}>
              <span className='icon'><IoMdAdd /></span>
              <span>Créer un chantier</span>
            </button>
          </div>
          <div className={styles.table}>
            {(worksites || [])?.length > 0 && (
              <WorksitesTable
                worksites={worksites}
                handleSorting={handleSorting}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Worksites;
