import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PhaseForm from '~/components/estimate/form/PhaseForm';
import { useGetEstimate } from '~/hooks/estimates';

import styles from './phase.module.scss';
import { IPhase } from '~/types/estimates';

const Phase = ({ isPreview }: { isPreview: boolean }) => {
  const { id, phaseId } = useParams();

  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const phase = useMemo(() => {
    const found = estimate?.phases.find((d: IPhase) => d._id === phaseId);
    return found;
  }, [estimate, phaseId]);

  return (
    <div className={styles.phase}>
      <div className={styles.content}>
        {(id && phase) && (
          <PhaseForm
            key={`phase-${phase._id}`}
            estimateId={id}
            phase={phase}
            isPreview={isPreview}
          />
        )}
      </div>
    </div>

  );
};

export default Phase;
