import React, { useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './infos.module.scss';
import InfosForm from '~/components/worksite/form/InfosForm';
import { useGetWorksite } from '~/hooks/worksites';
import PreviewField from '~/components/PreviewField';
import Todos from '~/components/Todos';

const Informations = () => {
  const { id } = useParams();
  const [isForm, setIsForm] = useState<boolean>(false);
  const {
    data: worksite,
  } = useGetWorksite(id || '');
  return (
    <div className={styles.infos}>
      {!isForm
        ? <>
        <main>
          <div className={styles.title}>
            <h4>Informations générales</h4>
            <span onClick={() => setIsForm(true)}>
              <GoPencil />
            </span>
          </div>
          <section>
            <div className={styles.field}>
              <PreviewField
                label="Description"
                value={worksite?.description || '---'}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <PreviewField
                  label="Nom du chantier"
                  value={worksite?.estimate?.worksite?.name || '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Numéro d’affaire"
                  value={'---'}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <PreviewField
                  label="Contact"
                  value={`${worksite?.estimate?.contact?.firstName} ${worksite?.estimate?.contact?.lastName}`}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Téléphone"
                  value={worksite?.estimate?.contact?.phone || '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Mail"
                  value={worksite?.estimate?.contact?.email || '---'}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <PreviewField
                  label="Date démarrage prévisionnelle"
                  value={worksite?.estimatedDates?.startDate ? format(new Date(worksite?.estimatedDates?.startDate), 'dd.MM.yyyy') : '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Date de fin prévisionnelle"
                  value={worksite?.estimatedDates?.endDate ? format(new Date(worksite?.estimatedDates?.endDate), 'dd.MM.yyyy') : '---'}
                />
              </div>
            </div>
            <hr/>
          </section>
          <section>
            <h4>Adresse</h4>
            <div className={styles.row}>
              <div className={styles.field}>
                <PreviewField
                  label="Adresse"
                  value={worksite?.estimate.worksite.address || '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Ville"
                  value={worksite?.estimate.worksite.city || '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Ville"
                  value={worksite?.estimate?.worksite?.department || '---'}
                />
              </div>
            </div>
            <PreviewField
              label="Complément adresse"
              value={worksite?.estimate.worksite.address2 || '---'}
            />
            <hr/>
          </section>
          <section>
            <h4>Informations complémentaires</h4>
            <div className={styles.row}>
              <div className={styles.field}>
                <PreviewField
                  label="Type de camion"
                  value={worksite?.estimate.transport?.trucks.type || '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Taille maximal camion"
                  value={worksite?.estimate?.transport?.trucks.maxSize || '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Matériel utilisé"
                  value={worksite?.estimate?.worksite?.materialUsed || '---'}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <PreviewField
                  label="Charge"
                  value={worksite?.estimate.worksite.load || '---'}
                />
              </div>
              <div className={styles.field}>
                <PreviewField
                  label="Stabilité"
                  value={worksite?.estimate?.worksite?.stability || '---'}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <PreviewField
                  label="Moyens Accès"
                  value={worksite?.estimate.worksite.access || '---'}
                />
              </div>
            </div>
          </section>
        </main>
        <aside>
          <Todos
            todos={worksite?.todos || []}
            worksiteId={id || ''}
          />
        </aside>
      </>
        : <InfosForm
          close={() => setIsForm(false)}
          worksite={worksite}
        />
      }
    </div>
  );
};

export default Informations;
