/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  createEstimate,
  createEstimatePhase,
  getEstimate,
  getEstimates,
  getEstimatesList,
  updateEstimate,
  updateEstimatePhase,
  addEstimateNote,
  updateEstimateNote,
  deleteEstimateNote,
  getStatsEstimates,
  createEstimateVarious,
  updateEstimateVarious,
  deleteEstimatePhase,
  deleteEstimateVarious,
  sendEstimate,
  createEstimateVersion,
  duplicateEstimate,
} from '../../api/estimates';
import {
  INote,
  ArgsGetEstimates, IEstimate,
  IPhase,
  IVarious,
} from '~/types/estimates';

const useGetEstimates = (params: ArgsGetEstimates) => useQuery({
  queryKey: ['estimates', params],
  queryFn: async (): Promise<IEstimate[]> => getEstimates(params),
  keepPreviousData: true,
});

const useGetEstimatesValidations = (params: ArgsGetEstimates) => useQuery({
  queryKey: ['estimates-validations', params],
  queryFn: async (): Promise<IEstimate[]> => getEstimates({
    ...params, status: 'review-pending', search: '', department: '', agency: '',
  }),
  keepPreviousData: true,
});

const useGetStatsEstimates = () => useQuery({
  queryKey: ['estimates-stats'],
  queryFn: async (): Promise<any | null> => getStatsEstimates(),
  keepPreviousData: true,
});

const useGetEstimatesList = () => useQuery({
  queryKey: ['estimates-list'],
  queryFn: async (): Promise<any | null> => getEstimatesList(),
  keepPreviousData: true,
});

const useGetEstimate = (id: string) => useQuery({
  queryKey: ['estimate', id],
  queryFn: async (): Promise<IEstimate | null> => getEstimate(id),
  keepPreviousData: false,
});

const useSendEstimate = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => sendEstimate(id),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({ queryKey: ['estimates-stats'] });
      queryEstimate.invalidateQueries({ queryKey: ['estimate'] });
      queryEstimate.invalidateQueries({ queryKey: ['estimates-validations'] });
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      console.log(estimate);
      queryEstimate.invalidateQueries({ queryKey: ['client', estimate.client] });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useCreateEstimateVersion = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => createEstimateVersion(id),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({ queryKey: ['estimates-stats'] });
      queryEstimate.invalidateQueries({ queryKey: ['estimate'] });
      queryEstimate.invalidateQueries({ queryKey: ['estimates-validations'] });
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      console.log(estimate);
      queryEstimate.invalidateQueries({ queryKey: ['client', estimate.client] });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDuplicateEstimate = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: { estimateId: string; clientId: string; }) => duplicateEstimate(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({ queryKey: ['estimates-stats'] });
      queryEstimate.invalidateQueries({ queryKey: ['estimate'] });
      queryEstimate.invalidateQueries({ queryKey: ['estimates-validations'] });
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.invalidateQueries({ queryKey: ['client', estimate.client] });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
      return estimate;
    },
  });
};

const useCreateEstimate = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IEstimate>) => createEstimate(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({ queryKey: ['estimates-stats'] });
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.invalidateQueries({ queryKey: ['client', estimate.client] });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateEstimate = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IEstimate>) => updateEstimate(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({ queryKey: ['estimates-stats'] });

      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.invalidateQueries({ queryKey: ['client', estimate.client._id] });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useCreateEstimatePhase = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (estimateId: string) => createEstimatePhase(estimateId),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDeleteEstimatePhase = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { phaseId: string, estimateId: string }) => deleteEstimatePhase(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useCreateEstimateVarious = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (estimateId: string) => createEstimateVarious(estimateId),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateEstimateVarious = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IVarious> & { estimateId : string }) => updateEstimateVarious(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDeleteEstimateVarious = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { variousId: string, estimateId: string }) => deleteEstimateVarious(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateEstimatePhase = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IPhase> & { estimateId : string }) => updateEstimatePhase(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useAddEstimateNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => addEstimateNote(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateEstimateNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => updateEstimateNote(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDeleteEstimateNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { noteId: string, estimateId: string }) => deleteEstimateNote(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

export {
  useGetEstimate,
  useGetEstimatesValidations,
  useSendEstimate,
  useGetStatsEstimates,
  useGetEstimates,
  useGetEstimatesList,
  useCreateEstimate,
  useUpdateEstimate,
  useCreateEstimateVersion,
  useDuplicateEstimate,
  useCreateEstimatePhase,
  useUpdateEstimatePhase,
  useDeleteEstimatePhase,
  useCreateEstimateVarious,
  useUpdateEstimateVarious,
  useDeleteEstimateVarious,
  useAddEstimateNote,
  useUpdateEstimateNote,
  useDeleteEstimateNote,
};
