import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import styles from './worksites.module.scss';

import WorksitesTable from '~/components/table/WorksitesTable';
import { useGetWorksites } from '~/hooks/worksites';

const Worksites = () => {
  const { id = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);
  const { data: worksites } = useGetWorksites({
    agency: id,
    ...paramsObject,
  });

  const handleSorting = (sort: any) => {
    if (!sort) return;
    // console.log(sort);
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.worksites}>
      <WorksitesTable
        worksites={worksites || []}
        handleSorting={handleSorting}
        isAgency
      />
    </div>
  );
};

export default Worksites;
