import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { useController, Control } from 'react-hook-form';
import styles from './checkbox.module.scss';

interface CheckboxProps {
  name: string,
  control: Control<any>,
  label?: string | null,
  defaultValue?: boolean,
  inline?: boolean,
  rules?: any | null,
  disabled?: boolean,
}

const Checkbox = ({
  name,
  control,
  label,
  inline = true,
  defaultValue = false,
  rules,
  disabled = false,
}: CheckboxProps) => {
  const { field: { value, onChange } } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  return (
    <div className={`${styles.checkbox} ${inline ? styles.inline : ''} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.container}>
        <div className={styles.square} onClick={() => onChange(!value)}>
          {value && <BsCheck size={30} />}
        </div>
      </div>
      {label && <label onClick={() => onChange(!value)}>{label}</label>}
    </div>
  );
};

export default Checkbox;
