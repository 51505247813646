import React, { useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { useGetClient } from '~/hooks/clients';
import { useGetEstimatesList } from '~/hooks/estimates';
import Modal from '~/lib/Modal';
import Loader from '~/components/Loader';
import BackButton from '~/components/BackButton';
import ClientForm from '~/components/form/ClientForm';
import ClientNotesForm from '~/components/form/ClientForm/ClientNotesForm';
import CreateEstimate from '~/components/estimate/form/CreateEstimate';
import styles from './detail.module.scss';
import { IEstimate } from '~/types/estimates';
import { IOption } from '~/types/options';
import { IWorksite } from '~/types/worksites';
import CreateWorksite from '~/components/worksite/form/CreateWorksite';

const DetailClient = () => {
  const modalEstimateRef = useRef<any>();
  const modalWorksiteRef = useRef<any>();
  const { id } = useParams();
  const { data: client, isLoading } = useGetClient(id || '');
  const navigate = useNavigate();

  const { data: list = {} } = useGetEstimatesList();
  const { status = [] } = list;

  const getStatus = useCallback((value: string) => (
    status
      .find((d: IOption) => d.value === value)
  ), [status]);

  return (
    <>
      <Modal width={450} ref={modalEstimateRef}>
        <CreateEstimate clientId={client?._id} onSuccess={(estimateId: string) => {
          modalEstimateRef.current.close();
          navigate(`/estimates/${estimateId}`);
        }} />
      </Modal>
      <Modal width={450} ref={modalWorksiteRef}>
        <CreateWorksite clientId={client?._id} onSuccess={(worksiteId: string) => {
          modalWorksiteRef.current.close();
          navigate(`/worksites/${worksiteId}`);
        }} />
      </Modal>
      <div className={styles.detailClient}>
        {isLoading && <Loader />}
        {client && (
          <>
            <div className={styles.content}>
              <div>
                <div className={styles.header}>
                  <div className={styles.titlePage}>
                    <BackButton />
                    <h1>{client?.name}</h1>
                  </div>
                </div>
                <ClientForm client={client} />
              </div>
              <div>
                <ClientNotesForm
                  clientId={client._id}
                  notes={client.notes}
                />
                <div className={styles.estimates}>
                  <div className={styles.estimatesHeader}>
                    <h4>Devis associés</h4>
                    <button onClick={() => modalEstimateRef.current.open()}>
                      <span className='icon'><IoMdAdd /></span>
                      <span>Créer un devis</span>
                    </button>
                  </div>
                  <div className={styles.estimatesList}>
                    {(client?.estimates || []).length > 0 && (
                      <>
                        {(client?.estimates || []).map((estimate: Partial<IEstimate>) => (
                          <div
                            className={styles.estimate}
                            onClick={() => navigate(`/estimates/${estimate._id}`)}
                            key={`estimate-${estimate._id}`}
                          >
                            <div>
                              <h3>{estimate.worksite?.name || 'Non renseigné'}</h3>
                              <p>{estimate.agency?.location || 'Non renseigné'}</p>
                              <p>
                                <span>Ville&nbsp;:&nbsp;</span>{estimate.worksite?.city || 'Non renseigné'}
                              </p>
                            </div>
                            <div>
                              {estimate?.status && (
                                <div className={styles.status}>
                                  <div
                                    className={styles.dot}
                                    style={getStatus(estimate.status).color
                                      ? { backgroundColor: getStatus(estimate.status).color }
                                      : {}
                                    }
                                  />
                                  {getStatus(estimate.status).label || estimate.status}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.estimates}>
                  <div className={styles.estimatesHeader}>
                    <h4>Chantiers associés</h4>
                    <button onClick={() => modalWorksiteRef.current.open()}>
                      <span className='icon'><IoMdAdd /></span>
                      <span>Créer un chantier</span>
                    </button>
                  </div>
                  <div className={styles.estimatesList}>
                    {(client?.worksites || []).length > 0 && (
                      <>
                        {(client?.worksites || []).map((worksite: Partial<IWorksite>) => (
                          <div
                            className={styles.estimate}
                            onClick={() => navigate(`/worksites/${worksite._id}`)}
                            key={`worksite-${worksite._id}`}
                          >
                            <div>
                              <h3>{worksite?.estimate?.worksite?.name || 'Non renseigné'}</h3>
                              <p>{worksite?.estimate?.agency?.location || 'Non renseigné'}</p>
                              <p>
                                <span>Ville&nbsp;:&nbsp;</span>{worksite?.estimate?.worksite?.city || 'Non renseigné'}
                              </p>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailClient;
