import React from 'react';
import { useParams } from 'react-router-dom';

import styles from './worksite-comments.module.scss';
import { useGetWorksite } from '~/hooks/worksites';
import WorksiteNotesForm from '~/components/worksite/form/WorksiteNotesForm';
import Comments from '~/components/worksite/Comments';

const WorksiteComments = () => {
  const { id } = useParams();
  const {
    data: worksite,
  } = useGetWorksite(id || '');

  return (
    <div className={styles.comments}>
      <div className={styles.col}>
        <div className={styles.notes}>
          {worksite?._id
          && <WorksiteNotesForm
            worksiteId={worksite?._id}
            notes={worksite?.notes}
          />
          }
        </div>
      </div>
      <div className={styles.col}>
      {worksite?._id
          && <Comments
          worksiteId={worksite?._id}
          comments={worksite?.comments}
        />
      }
      </div>
    </div>
  );
};

export default WorksiteComments;
