import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import styles from './infos-worksite.module.scss';

import {
  InputText,
  StyledSelect,
  Textarea,
} from '~/lib/HooksFormFields';
import { useGetWorksitesList, useUpdateWorksite } from '~/hooks/worksites';
import { IWorksite } from '~/types/worksites';

const InfosForm = ({
  close,
  worksite,
} : {
  close: () => void,
  worksite?: IWorksite | null
}) => {
  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      _id: worksite?._id,
      description: worksite?.description,
      author: worksite?.author?._id,
      referrer: worksite?.referrer?._id,
      estimatedDates: {
        startDate: worksite?.estimatedDates?.startDate ? format(new Date(worksite?.estimatedDates.startDate), 'yyyy-MM-dd') : null,
        endDate: worksite?.estimatedDates?.endDate ? format(new Date(worksite?.estimatedDates.endDate), 'yyyy-MM-dd') : null,
      },
    },
  });
  const { data: list = {} } = useGetWorksitesList();

  const {
    isLoading,
    mutateAsync: updateWorksite,
  } = useUpdateWorksite();

  const {
    salesUsers = [],
  } = list;

  const submit = async (data: any) => {
    try {
      await updateWorksite(data);
      close();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.form}>
      <h4>Informations générales</h4>
      <div className={styles.fields}>
        <div className={styles.containerField}>
            <Textarea
              name='description'
              label='Description du chantier'
              control={control}
            />
          </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              name='author'
              label='Chargé d’affaire'
              control={control}
              options={salesUsers}
              isSearchable
            />
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name='referrer'
              label='Responsable chantier'
              control={control}
              options={salesUsers}
              isSearchable
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              type="date"
              name='estimatedDates.startDate'
              label='Date démarrage prévisionnelle'
              control={control}
            />
          </div>
          <div className={styles.containerField}>
          <InputText
              type="date"
              name='estimatedDates.endDate'
              label='Date de fin prévisionnelle'
              control={control}
            />
          </div>
        </div>
      </div>
        <button className={styles.submit} disabled={isLoading} onClick={handleSubmit(submit)}>
          <span className='icon'><BsCheck /></span>
          <span>Valider les informations</span>
        </button>
    </div>
  );
};

export default InfosForm;
