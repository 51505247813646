import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { LuLogOut } from 'react-icons/lu';
import { useSignOut, useUserProfile } from '../../hooks/auth';
import styles from './navbar.module.scss';
import { IUser } from '~/types/users';
import { useDeleteNotif, useGetNotifs } from '~/hooks/notifs';
import { INotif } from '~/types/notifs';

const formatDate = (date: Date) => {
  const dateOptions : any = { day: 'numeric', month: 'long' };
  const timeOptions : any = { hour: '2-digit', minute: '2-digit' };

  const formattedDate = date.toLocaleDateString('fr-FR', dateOptions);
  const formattedTime = date.toLocaleTimeString('fr-FR', timeOptions).replace(':', 'h');

  return `Le ${formattedDate} à ${formattedTime}`;
};

const Notif = ({ notif, close }:{ notif: INotif, close: () => void }) => {
  const {
    mutate: deleteNotif,
  } = useDeleteNotif();
  const date = notif.createdAt && formatDate(new Date(notif.createdAt));
  return (<div className={styles.notif}>
    <label>{date}</label>
    <Link to={`/estimates/${notif.estimateId}`} onClick={() => {
      deleteNotif(notif._id);
      close();
    }}>
      <p dangerouslySetInnerHTML={{ __html: notif.message || '' }}></p>
    </Link>
  </div>);
};

const Navbar = () => {
  const signOut = useSignOut();
  const { data } = useUserProfile();
  const user: Partial<IUser> = data || {};
  const { data: notifs = [] } = useGetNotifs();
  const [notifIsOpen, setNotifIsOpen] = useState(false);
  console.log(notifs);
  return (
    <div className={styles.navbar}>
      <ul>
        <li>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='' >Accueil</NavLink>
        </li>
        {user?.role === 'Admin' && (
          <>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='agencies' >Agences</NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='users' >Membres</NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='estimates' >Devis</NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='worksites' >Chantiers</NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='clients' >Clients</NavLink>
        </li>
      </ul>
      <div className={styles.profile}>
        {notifs?.length > 0
          && <div className={styles['notifs-toggle']}
            onClick={() => setNotifIsOpen(!notifIsOpen)}
          >
            {notifs.length}
          </div>
        }
        <NavLink className={styles.user} to='/account' >
          <div className={styles.initial}>
            <span>
              {user.profile?.firstName[0]}
              {user.profile?.lastName[0]}
            </span>
          </div>
          <p>{user.profile?.firstName} {user.profile?.lastName}</p>
        </NavLink>
        <button className='invisible' onClick={signOut}>
          <LuLogOut size={26} />
        </button>
      </div>
      <div className={`${styles['notifs-container']} ${notifIsOpen ? styles.isOpen : ''}`}
        onMouseLeave={() => setNotifIsOpen(false)}
      >
        <div className={styles.notifs}>
          {notifs.map((n: INotif) => <Notif
            key={n._id}
            notif={n}
            close={() => setNotifIsOpen(false)}
          />)}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
