import React, {
  useMemo, useRef, useState,
} from 'react';
import { LuBellRing } from 'react-icons/lu';
import { BiSolidBellRing } from 'react-icons/bi';
import { FieldValues, useForm } from 'react-hook-form';
import { BsCheck } from 'react-icons/bs';
import { format } from 'date-fns';
import styles from './estimates-callback-form.module.scss';
import { ICallback } from '~/types/estimates';
import Modal from '~/lib/Modal';
import { ErrorField, InputText } from '~/lib/HooksFormFields';
import { useUpdateEstimate } from '~/hooks/estimates';
import { daysDifference } from '~/utils';

const CallbackModal = ({
  estimateId,
  callbacks,
  close,
  defaultCallback,
} : {
  estimateId: string,
  callbacks: ICallback[]
  close: () => void,
  defaultCallback: null | ICallback
}) => {
  const {
    mutate: updateEstimate,
  } = useUpdateEstimate();
  const { control, handleSubmit, formState: { errors } } = useForm<FieldValues>({
    defaultValues: defaultCallback || {
      isActive: true,
    },
  });

  async function onSubmit(form: FieldValues) {
    const currentCallbacks : any = [...callbacks];
    const index : number = callbacks.findIndex((c: ICallback) => c._id === form._id);
    if (index !== -1) {
      currentCallbacks[index] = form;
    } else {
      currentCallbacks.push(form);
    }

    const data : any = {
      callbacks: currentCallbacks,
      _id: estimateId,
    };
    await updateEstimate(data);
    await close();
  }

  async function deleteCallback() {
    const currentCallbacks : any = [...callbacks.filter(
      (c:ICallback) => c._id !== defaultCallback?._id,
    )];
    const data : any = {
      callbacks: currentCallbacks,
      _id: estimateId,
    };
    await updateEstimate(data);
    await close();
  }

  return (
    <div className={styles.modal}>
      <h3>Ajouter un rappel</h3>
      <div className={styles.field}>
        <InputText
          type='date'
          name='date'
          label="Choisir une date"
          control={control}
          rules={{
            required: 'La date est requise',
          }}
        />
        {errors?.date?.message && <ErrorField message={errors?.date?.message} />}
      </div>
      <div className={styles.field}>
        <InputText
          name='message'
          label="Texte"
          control={control}
          rules={{
            required: 'le Texte est requis',
          }}
        />
        {errors?.message?.message && <ErrorField message={errors?.message?.message} />}
      </div>
      <div className={styles.actions}>
        {defaultCallback?._id
          ? <button
            type="button"
            className={styles.delete}
            onClick={() => deleteCallback()}
          >
            Supprimer le rappel
          </button>
          : <span />

        }
        <div>
          <button
            type="button"
            className={styles.cancel}
            onClick={() => close()}
          >
            Annuler
          </button>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

const Callback = ({
  callback,
  estimateId,
  callbacks,
  handleUpdate,
} : {
  callback: ICallback,
  estimateId: string,
  callbacks: ICallback[]
  handleUpdate: () => void,
}) => {
  const {
    mutate: updateEstimate,
  } = useUpdateEstimate();

  async function handleChangeStatus() {
    const currentCallbacks = [...callbacks];
    const index : number = callbacks.findIndex((c: ICallback) => c._id === callback._id);
    if (index !== -1) {
      currentCallbacks[index].isActive = !currentCallbacks[index].isActive;
    }

    const data : any = {
      callbacks: currentCallbacks,
      _id: estimateId,
    };
    await updateEstimate(data);
  }

  const days = useMemo(() => daysDifference(new Date(callback.date)), [callback.date]);

  const getColor = useMemo(() => {
    if (callback.isActive && days <= 0) return '#FC8E8F';
    if (callback.isActive) return '#1B2559';
    return '#B8BDCB';
  }, [days, callback.isActive]);

  return (
    <div className={styles.callback}>
      <div className={styles.message}>
        <div
        className={`${styles.check} ${callback.isActive && styles.active}`}
        onClick={() => handleChangeStatus()}
        >
          <BsCheck />
        </div>
        <BiSolidBellRing color={getColor}/>
       <p onClick={() => handleUpdate()} style={{ color: getColor }}>{callback?.message}</p>
       </div>
       {callback.isActive && days === 0 && <p className={styles.days}>Aujourd&#39;hui</p>}
       {callback.isActive && days < 0 && <p className={styles.days}>
        {Math.ceil(Math.abs(days))}j de retard</p>}
       {callback.isActive
       && days > 0 && <p className={styles.days}>{Math.ceil(Math.abs(days))} Jours</p>}
    </div>
  );
};

const EstimateCallbackForm = ({
  estimateId,
  callbacks,
} : {
  estimateId: string,
  callbacks: ICallback[]
}) => {
  const modalRef = useRef<any>();
  const [updatedCallback, setUpdatedCallback] = useState<null | any>(null);

  const close = () => {
    modalRef.current.close();
    setUpdatedCallback(null);
  };

  const handleUpdateModal = (value: ICallback) => {
    modalRef.current.open();
    setUpdatedCallback({ ...value, date: format(new Date(value?.date), 'yyyy-MM-dd') });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.header}>
            <h4>Rappels</h4>
            <button onClick={() => modalRef.current.open()}>
              <LuBellRing size={20} />
            </button>
          </div>
          <div className={styles.callbacks}>
            {callbacks.map((c:ICallback) => <Callback
              key={c._id}
              callback={c}
              estimateId={estimateId}
              callbacks={callbacks}
              handleUpdate={() => handleUpdateModal(c)}
            />)}
          </div>
        </div>
      </div>
      <Modal
        ref={modalRef}
        width={400}
      >
        <CallbackModal
          estimateId={estimateId}
          callbacks={callbacks}
          defaultCallback={updatedCallback}
          close={() => close()}
        />
      </Modal>
    </>
  );
};

export default EstimateCallbackForm;
