import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './roofProtection-form.module.scss';

import {
  Checkbox,
  InputNumber,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';

const RoofProtectionForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const isRoofProtection = watch('roofProtection.isRoofProtection');
  const phase = watch();
  useEffect(() => {
    if (!isRoofProtection) {
      setValue('roofProtection.surface', null);
    }
  }, [isRoofProtection]);
  return (
    <div id="roofProtection" className={styles.roofProtection}>
      <h4>Protection de Toit</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='roofProtection.isRoofProtection'
            label='Avec protection de Toit'
            control={control}
            disabled={isPreview}
          />
        </div>
      </div>

        {isRoofProtection
        && <div className={styles.row}>
          <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
              label='Nombres de m²'
              value={phase?.roofProtection?.surface ? `${phase.roofProtection.surface} m²` : '---'}
            />
            : <InputNumber
              name='roofProtection.surface'
              label='Nombres de m²'
              max={100}
              control={control}
            />
            }
          </div>
        </div>
        }
    </div>
  );
};

export default RoofProtectionForm;
