import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useGetEstimatesList, useDuplicateEstimate } from '~/hooks/estimates';
import styles from './duplicate-estimate.module.scss';

import {
  ErrorField,
  StyledSelect,
} from '~/lib/HooksFormFields';

const DuplicateEstimate = ({
  clientId,
  estimateId,
  close,
} : {
  clientId?: string,
  estimateId?: string,
  close: () => void,
}) => {
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      estimateId,
      clientId,
    },
  });
  const { data: list = {} } = useGetEstimatesList();
  const {
    isLoading,
    mutateAsync: duplicateEstimate,
  } = useDuplicateEstimate();

  const {
    clients = [],
  } = list;

  const submit = async (data: any) => {
    try {
      const { estimate } = await duplicateEstimate(data);
      navigate(`/estimates/${estimate?._id}`);
      close();
    } catch (e) {
      console.log(e);
    }
  };

  console.log(clientId);

  return (
    <div className={styles.form}>
      <h3>Dupliquer le devis</h3>
      <div className={styles.fields}>
        <div className={styles.containerField}>
          <StyledSelect
            name='clientId'
            label='Choisir un client'
            control={control}
            options={clients}
            isSearchable
            rules={{
              required: 'le client est requis',
            }}
          />

          {errors.clientId?.message && <ErrorField message={errors.clientId?.message}/>}
        </div>
      </div>
      <div className={styles.buttons}>
        <button className='invisible' onClick={() => close()}>Annuler</button>
        <button disabled={isLoading} onClick={handleSubmit(submit)}>
          <span className='icon'><BsCheck /></span>
          <span>Valider</span>
        </button>
      </div>
    </div>
  );
};

export default DuplicateEstimate;
