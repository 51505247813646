import React from 'react';
import { MdDiscount } from 'react-icons/md';
import styles from './preview-field.module.scss';

const PreviewField = ({
  label,
  value,
  className,
}: {
  label: string,
  value: string,
  className?:string
}) => (
    <div className={`${styles.field} ${className && styles[className]}`}>
      <span className={styles.label}>
       {className === 'discount' && <MdDiscount />} {label}
      </span>
      <p>{value}</p>
    </div>
);

export default PreviewField;
