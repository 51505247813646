import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './nets-form.module.scss';

import {
  Checkbox,
} from '~/lib/HooksFormFields';

const NetsForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control } = useFormContext();

  return (
    <div id="nets" className={styles.nets}>
      <h4>Filets</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='nets.isNets'
            label='Filets'
            control={control}
            disabled={isPreview}
          />
        </div>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='nets.isWovenFabrics'
            label='Toiles Tissées'
            control={control}
            disabled={isPreview}
          />
        </div>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='nets.isTarpaulins'
            label='Baches'
            control={control}
            disabled={isPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default NetsForm;
