import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsCheck } from 'react-icons/bs';
import { FiEye } from 'react-icons/fi';
import { LuUpload } from 'react-icons/lu';
import { format } from 'date-fns';
import { IoIosSend } from 'react-icons/io';
import styles from './export.module.scss';
import { useGetEstimatesList, useSendEstimate, useUpdateEstimate } from '~/hooks/estimates';
import { IEstimate } from '~/types/estimates';
import { IOption } from '~/types/options';
import { exportEstimate } from '~/api/estimates';
import Loader from '~/components/Loader';

const ModalExport = ({
  title = 'Exporter le devis',
  estimate,
  close,
} : {
  title?: string
  estimate: IEstimate,
  close: () => void,
}) => {
  const isSend = title === 'Envoyer le devis';
  const { data: list } = useGetEstimatesList();
  const {
    mutate: updateEstimate,
  } = useUpdateEstimate();
  const {
    mutate: sendEstimate,
  } = useSendEstimate();
  const [isLoading, setIsLoading] = useState(false);
  const clientFeesOptions = list?.clientFees || [];
  const date = format(new Date(), 'dd-MM-yyyy');

  const { setValue, watch } = useForm({
    defaultValues: {
      clientFees: estimate?.clientFees || [],
    },
  });

  const clientFees = watch('clientFees') || [];

  function handleChangeClientFees(value: string) {
    let current = [...clientFees];
    if (current.find((c) => c === value)) {
      current = current.filter((c) => c !== value);
    } else {
      current.push(value);
    }
    setValue('clientFees', current);
  }

  const handleExport = async (filename: string | null = null) => {
    await updateEstimate({
      _id: estimate?._id,
      clientFees,
    });
    setIsLoading(true);

    await exportEstimate(estimate?._id, filename);
    setIsLoading(false);
    close();
  };

  const handleSend = async () => {
    await updateEstimate({
      _id: estimate?._id,
      clientFees,
    });
    setIsLoading(true);

    await sendEstimate(estimate?._id);
    setIsLoading(false);
    close();
  };

  return (
    <div className={styles.export}>
      <h3>{title}</h3>
      <p>Définir les prestations à la charge du client</p>
      <ul>
        {clientFeesOptions.map((cf: IOption) => <li
          key={cf.value}
          onClick={() => handleChangeClientFees(`${cf.value}`)}
          className={clientFees.find((c) => c === cf.value) ? styles.active : ''}
        >
          <div className={styles.check}>
            <BsCheck />
          </div>
          <p>{cf.label}</p>
        </li>)}
      </ul>
      {!isLoading && estimate?._id
        ? <div className={styles.actions}>
          <button className={styles.cancel}
            onClick={() => close()}
          >
            Annuler
          </button>
          {!isSend
            ? <>
            <button className={styles.submit}
              onClick={() => handleExport()}
            >
              <FiEye />
              Prévisualiser
            </button>
            <button className={styles.submit}
              onClick={() => handleExport(`${estimate.worksite.name}-${date}.pdf`)}
            >
              <LuUpload />
              Exporter
            </button>
          </>
            : <>
          <button className={styles.submit}
            onClick={() => handleSend()}
          >
            <IoIosSend />
            Envoyer
          </button>
        </>
        }
        </div>
        : <div className={styles.actions}>
          <div className={styles.loader}>
            <Loader small />
            <p>Export en cours</p>
          </div>
        </div>
      }
    </div>
  );
};

export default ModalExport;
