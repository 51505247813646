import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './handling-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import { useGetEstimatesList } from '~/hooks/estimates';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';

const HandlingForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const { data: list = {} } = useGetEstimatesList();
  const isHandling = watch('handling.type');
  const phase = watch();

  useEffect(() => {
    if (!isHandling) {
      setValue('handling.percentage', null);
    }
  }, [isHandling]);
  return (
    <div id="manutention" className={styles.handling}>
      <h4>Manutention</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Type de manutention'
              value={list?.handlingTypes?.find((a:IOption) => a.value === phase?.handling?.type)?.label || '---'}
            />
          : <StyledSelect
            label='Type de manutention'
            name='handling.type'
            control={control}
            options={list?.handlingTypes}
          />
          }
        </div>
        {isHandling
        && <div className={styles.row}>
          <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
              label='% de manutention'
              value={phase?.handling?.percentage ? `${phase?.handling?.percentage} %` : '---'}
            />
            : <InputNumber
              name='handling.percentage'
              label='% de manutention'
              max={100}
              control={control}
            />
          }
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default HandlingForm;
