import React, { useState } from 'react';
import {
  Routes, Route,
  useParams,
  NavLink,
  useLocation,
} from 'react-router-dom';
import { LuDownload } from 'react-icons/lu';
import styles from './worksite.module.scss';

import Loader from '~/components/Loader';
import { useGetWorksite } from '~/hooks/worksites';
import { exportEstimate } from '~/api/estimates';
import BackButton from '~/components/BackButton';
import Informations from './informations';
import Ged from './ged';
import GedCategory from './gedCategory';
import Phases from './phases';
import WorksiteComments from './comments';

const Worksite = () => {
  const { id, ...params } = useParams();
  const location = useLocation();
  const [exportIsLoading, setExportIsLoading] = useState(false);

  const {
    isLoading,
    data: worksite,
  } = useGetWorksite(id || '');

  const getIsActive = (path: string) => {
    let active = false;
    const url = `/worksites/${id}${path}`;

    if (location.pathname === url) active = true;
    if (path === '/files' && location.pathname.includes(url)) {
      active = true;
    }
    return active;
  };

  async function handleExport() {
    if (!worksite?.estimate?._id) return;
    await setExportIsLoading(true);
    await exportEstimate(worksite?.estimate?._id, `${worksite?.estimate?.worksite?.name}.pdf`);
    await setExportIsLoading(false);
  }

  const gedUrl = params['*']?.includes('files') && params['*']?.split('/').length === 2 && `/worksites/${id}/files`;

  return (
    <div className={styles.worksite}>
      {isLoading && (
        <div className={styles.loader}><Loader /></div>
      )}
      {worksite && (
        <>
        <header>
          <div className={styles['header-content']}>
            <div className={styles.title}>
              <BackButton path={gedUrl || '/worksites'}/>
              <h2>{worksite?.estimate?.worksite?.name}</h2>
              <p>{worksite?.client?.name}</p>
            </div>
            <div className={styles.nav}>
              <nav>
                <NavLink className={getIsActive('') ? styles.active : ''} to=''>Informations</NavLink>
                <NavLink className={getIsActive('/files') ? styles.active : ''} to='files'>GED</NavLink>
                <NavLink className={getIsActive('/phases') ? styles.active : ''} to='phases'>Phases</NavLink>
                <NavLink className={getIsActive('/comments') ? styles.active : ''} to='comments'>Commentaires</NavLink>
              </nav>
              <button
                className={styles.download}
                type='button'
                onClick={() => handleExport()}
              >
                {exportIsLoading
                  ? <><span><Loader small /></span>Téléchargement en cours</>
                  : <><span><LuDownload size={18}/></span>Télécharger le devis</>}
              </button>
            </div>
          </div>
        </header>
        <div className={styles.content}>
            <div className={styles.tabs}>
              <Routes>
                <Route path='/' element={<Informations />} />
                <Route path='/files/:type' element={<GedCategory />} />
                <Route path='/files' element={<Ged />} />
                <Route path='/phases' element={<Phases />} />
                <Route path='/comments' element={<WorksiteComments />} />
              </Routes>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Worksite;
