import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteNotification, getNotifications } from '../../api/notifs';

const useGetNotifs = () => useQuery({
  queryKey: ['Notifs'],
  queryFn: async (): Promise<any> => getNotifications(),
  keepPreviousData: true,
});

const useDeleteNotif = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteNotification({ id }),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Notifs'] });
    },
  });
};

export {
  useGetNotifs,
  useDeleteNotif,
};
