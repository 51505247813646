import React, { useEffect, useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { FieldValues, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { BiPlus } from 'react-icons/bi';
import { IoTrash } from 'react-icons/io5';
import styles from './phase-form.module.scss';

import {
  InputNumber,
  InputText,
} from '~/lib/HooksFormFields';

import { IWorksite, WorksiteFitter, WorksitePhase } from '~/types/worksites';
import { useUpdateWorksite } from '~/hooks/worksites';
import Modal from '~/lib/Modal';
import FitterModal from '../FitterModal';

const Fitter = ({
  fitter,
  openFitter,
}:{
  fitter: WorksiteFitter,
  openFitter: () => void
}) => (
    <div className={`${styles.fitter}`}
      onClick={() => openFitter()}
    >
      <div className={styles.title}>
        <h4>{fitter.user.profile.firstName} {fitter.user.profile.lastName}</h4>
      </div>
      <p>
        {fitter.startDate && `Date de début : ${format(new Date(fitter.startDate), 'dd.MM.yyyy')}`}
        {fitter.endDate && ` - Date de fin : ${format(new Date(fitter.endDate), 'dd.MM.yyyy')}`}
      </p>
    </div>
);

const WorksitePhaseForm = ({
  worksite,
  phase,
  close,
} : {
  worksite?: IWorksite | null,
  phase: WorksitePhase,
  close: () => void,
}) => {
  const fitterModalRef = useRef<any>();
  const [selectedFitter, setSelectedFitter] = useState<WorksiteFitter | null>(null);
  const {
    isLoading,
    mutateAsync: updateWorksite,
  } = useUpdateWorksite();

  const {
    control, handleSubmit, reset,
  } = useForm<FieldValues>({
    defaultValues: {
      ...phase,
      startDate: phase?.startDate ? format(new Date(phase.startDate), 'yyyy-MM-dd') : null,
      endDate: phase?.endDate ? format(new Date(phase.endDate), 'yyyy-MM-dd') : null,
      fitters: phase.fitters.map(
        (f) => ({ ...f, user: f.user?._id }),
      ),
    },
  });

  const submit = async (data: any) => {
    try {
      const phases = worksite?.phases ? [...worksite.phases.map(
        (p) => ({
          ...p,
          fitters: p.fitters.map(
            (f) => ({ ...f, user: f.user?._id }),
          ),
        }),
      )] : [];
      const index = phases.findIndex((p) => p._id === data._id);
      if (index !== -1) {
        phases[index] = data;
      }
      const payload : any = {
        _id: worksite?._id,
        phases,
      };
      const { worksite: worksiteUpdated } = await updateWorksite(payload);
      if (worksiteUpdated) {
        close();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deletePhase = async () => {
    try {
      const phases = worksite?.phases ? [...worksite.phases.map(
        (p) => ({
          ...p,
          fitters: p.fitters.map(
            (f) => ({ ...f, user: f.user?._id }),
          ),
        }),
      )] : [];
      const payload : any = {
        _id: worksite?._id,
        phases: phases.filter((p:any) => p._id !== phase?._id),
      };
      const { worksite: worksiteUpdated } = await updateWorksite(payload);
      if (worksiteUpdated) {
        close();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (phase) {
      const resetPhase = {
        ...phase,
        startDate: phase?.startDate ? format(new Date(phase.startDate), 'yyyy-MM-dd') : null,
        endDate: phase?.endDate ? format(new Date(phase.endDate), 'yyyy-MM-dd') : null,
        fitters: phase.fitters.map(
          (f) => ({ ...f, user: f.user?._id }),
        ),
      };
      reset(resetPhase);
    }
  }, [phase]);

  return (
    <>
      <div className={styles.form}>
        <div className={`${styles.containerField} ${styles.name}`}>
          <InputText
            name="name"
            control={control}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              type="date"
              label="Date de début"
              name="startDate"
              control={control}
            />
          </div>
          <div className={styles.containerField}>
            <InputText
              type="date"
              label="Date de fin"
              name="endDate"
              control={control}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputNumber
              label="Nombre d’heures prévues"
              name="hours"
              control={control}
            />
          </div>
        </div>
        <hr/>
        <div className={styles.fitters}>
          <div className={styles.title}>
            <h4>Monteurs</h4>
            <button
              className={styles.add}
              disabled={isLoading}
              onClick={() => fitterModalRef.current.open()}
            >
              <span className='icon'><BiPlus size={20} /></span>
              <span>Ajouter un monteur</span>
            </button>
          </div>
          <div className={styles.list}>
            {phase.fitters.map((f:WorksiteFitter) => <Fitter
              key={f._id}
              fitter={f}
              openFitter={() => {
                fitterModalRef?.current?.open();
                setSelectedFitter(f);
              }}
            />)}
          </div>
        </div>
        <div className={styles.buttons}>
        {phase?._id
          ? <button className={styles.delete}
            onClick={() => deletePhase()}
          >
            <span className='icon'><IoTrash /></span>
            <span>Supprimer la phase</span>
          </button>
          : <span></span>}
          <button disabled={isLoading} onClick={handleSubmit(submit)}>
            <span className='icon'><BsCheck /></span>
            <span>Valider</span>
          </button>
        </div>
      </div>
      <Modal ref={fitterModalRef} width={550}>
        <FitterModal
          worksite={worksite}
          phaseId={phase._id}
          fitter={selectedFitter}
          close={() => {
            fitterModalRef?.current?.close();
            setSelectedFitter(null);
          }}
        />
      </Modal>
    </>
  );
};

export default WorksitePhaseForm;
