import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BiPlus } from 'react-icons/bi';
import { format } from 'date-fns';
import styles from './phases.module.scss';
import { useGetWorksite } from '~/hooks/worksites';
import Modal from '~/lib/Modal';
import CreatePhase from '~/components/worksite/form/CreatePhase';
import { WorksitePhase } from '~/types/worksites';
import WorksitePhaseForm from '~/components/worksite/form/PhaseForm';

const Phase = ({
  phase,
  isActive,
  setSelectedPhase,
}:{
  phase: WorksitePhase,
  isActive: boolean,
  setSelectedPhase: () => void
}) => {
  console.log(isActive, phase);
  return (
    <div className={`${styles.phase} ${isActive ? styles.active : ''}`}
      onClick={() => setSelectedPhase()}
    >
      <div className={styles.title}>
        <h4>{phase.name}</h4>
        <p>{phase.hours && `${phase.hours} h`}</p>
      </div>
      <p>
        {phase.startDate && `Date de début : ${format(new Date(phase.startDate), 'dd.MM.yyyy')}`}
        {phase.endDate && ` - Date de fin : ${format(new Date(phase.endDate), 'dd.MM.yyyy')}`}
      </p>
    </div>

  );
};

const Phases = () => {
  const { id } = useParams();
  const addModalRef = useRef<any>(null);
  const [selectedPhase, setSelectedPhase] = useState<string | null>(null);
  const {
    data: worksite,
  } = useGetWorksite(id || '');

  const phases = useMemo(() => {
    const today = new Date();
    const worksitesPhases = worksite?.phases || [];
    return {
      current: worksitesPhases.filter(
        (d) => (d.endDate && new Date(d.endDate) > today) || !d.endDate,
      ),
      old: worksitesPhases.filter((d) => d.endDate && new Date(d.endDate) < today),
    };
  }, [worksite?.phases]);

  const activePhase = useMemo(() => {
    if (!selectedPhase) {
      return null;
    }
    return worksite?.phases?.find((p:WorksitePhase) => p._id === selectedPhase);
  }, [selectedPhase, worksite?.phases]);

  return (
    <div className={styles.phases}>
      <div className={styles.col}>
        <button
          type='button'
          className={styles.add}
          onClick={() => addModalRef?.current?.open()}
        >
          <BiPlus />
          Ajouter une phase
        </button>
        <div className={styles['phases-list']}>
          {phases.current.map(
            (p: WorksitePhase) => (<Phase
              key={p._id}
              phase={p}
              isActive={p._id === selectedPhase}
              setSelectedPhase={() => setSelectedPhase(p?._id)}
            />),
          )}
        </div>
        <h4>Phases passées</h4>
        <div className={styles['phases-list']}>
          {phases.old.map(
            (p: WorksitePhase) => (<Phase
              key={p._id}
              phase={p}
              isActive={p._id === selectedPhase}
              setSelectedPhase={() => setSelectedPhase(p?._id)}
            />),
          )}
        </div>
      </div>
      <div className={styles.col}>
        {activePhase && <WorksitePhaseForm
          phase={activePhase}
          worksite={worksite}
          close={() => setSelectedPhase(null)}
        />}
      </div>
      <Modal ref={addModalRef} width={550}>
        <CreatePhase
          worksite={worksite}
          onSuccess={(value: WorksitePhase) => setSelectedPhase(value?._id)}
          close={() => addModalRef?.current?.close()}
        />
      </Modal>
    </div>
  );
};

export default Phases;
