import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './boards-form.module.scss';

import {
  InputNumber,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';

const BoardsForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const phase = watch();
  return (
    <div id="board" className={styles.boards}>
      <h4>Fourniture Pose de Planches</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
            label='Fourniture : Nombre de ml'
            value={phase?.boards?.countLaying ? `${phase?.boards?.countLaying} ml` : '---'}
          />
          : <InputNumber
            name='boards.countLaying'
            label='Fourniture : Nombre de ml'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Pose : Nombre de planches'
              value={phase?.boards?.width ? `${phase?.boards?.width}` : '---'}
            />
          : <InputNumber
            name='boards.width'
            label='Pose : Nombre de planches'
            control={control}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default BoardsForm;
