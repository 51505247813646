import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { LuUpload } from 'react-icons/lu';
import { IoDuplicateSharp } from 'react-icons/io5';
import { IoIosSend } from 'react-icons/io';
import EstimateNotesForm from '~/components/estimate/form/EstimateNotesForm';
import styles from './general-sidebar.module.scss';
import { HistoryItem, IEstimate } from '~/types/estimates';
import Modal from '~/lib/Modal';
import ModalExport from '../ModalExport';
import { useCreateEstimateVersion, useGetEstimatesList } from '~/hooks/estimates';
import { IOption } from '~/types/options';
import EstimateCallbackForm from '../form/EstimateCallbackForm';
import DuplicateEstimate from '../form/DuplicateEstimate';

function formatDate(date : Date) {
  const formattedDate = date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
  const formattedTime = date.toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).replace(':', 'h');

  return `Envoyé le ${formattedDate} à ${formattedTime}`;
}

const GeneralSidebar = ({ estimate, isPreview } : { estimate : IEstimate, isPreview: boolean }) => {
  const {
    mutate: createEstimateVersion,
  } = useCreateEstimateVersion();

  const { data: list = {} } = useGetEstimatesList();
  const { status } = list;

  const exportRef = useRef<any>();
  const duplicateRef = useRef<any>();
  const sendRef = useRef<any>();

  const statusColor = status?.find((s: IOption) => s.value === estimate.status)?.color;

  return (
  <div className={styles.sidebar}>
    {estimate?._id
      && <div className={styles.actions}>
        {!isPreview
          && <button
            onClick={() => sendRef.current.open()}
          >
            <IoIosSend />Envoyer
          </button>
        }
        <button
          onClick={() => exportRef.current.open()}
        >
          <LuUpload />Exporter
        </button>
        <button
          className={styles.copy}
          onClick={() => duplicateRef.current.open()}
        >
          <IoDuplicateSharp />
        </button>
      </div>
    }
    <hr />
    <div className={styles.infos}>
      <p>
        Nom du client&nbsp;:&nbsp;
        <span>{estimate.client.name}</span>
      </p>
      <p>
        Commercial&nbsp;:&nbsp;
        <span>{estimate.author.profile?.firstName} {estimate.author.profile?.lastName}</span>
      </p>
    </div>
    <hr />
    <div className={styles.notes}>
      <EstimateNotesForm
        estimateId={estimate._id}
        notes={estimate.notes}
      />
    </div>
    <hr />
    <div className={styles.notes}>
      <EstimateCallbackForm
        estimateId={estimate._id}
        callbacks={estimate.callbacks || []}
      />
    </div>
    <hr />
    {(estimate?.status === 'refused' || estimate?.history?.length > 0)
      && <div className={styles.history}>
      <div className={styles.form}>
        <div className={styles.header}>
          <h4>Historique</h4>
          {estimate?.status === 'refused' && !estimate.parent
            && <button
              type="button"
              onClick={() => createEstimateVersion(estimate?._id)}
            >
              Créer une nouvelle version
            </button>
          }
        </div>
        {estimate.history.map((h: HistoryItem) => <Link
          to={`/estimates/${h.estimate}`}
          className={estimate._id === h.estimate ? styles.active : ''}
          key={h._id}>
          {estimate._id === h.estimate
          && <div className={styles.puce}
            style={{ backgroundColor: statusColor }}
          >

          </div>
          }
          <p>{h.name}</p>
          <p>{h.name !== 'Version actuelle' && formatDate(new Date(h.sendAt))}</p>
        </Link>)}
      </div>
    </div>
    }
    <Modal ref={exportRef} width={700}>
      <ModalExport
        estimate={estimate}
        close={() => exportRef?.current.close()}
      />
    </Modal>
    <Modal ref={sendRef} width={700}>
      <ModalExport
        title='Envoyer le devis'
        estimate={estimate}
        close={() => sendRef?.current.close()}
      />
    </Modal>
    <Modal ref={duplicateRef} width={400}>
      <DuplicateEstimate
        estimateId={estimate._id}
        clientId={estimate?.client?._id}
        close={() => duplicateRef?.current.close()}
      />
    </Modal>
  </div>
  );
};

export default GeneralSidebar;
