import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import styles from './worksites-table.module.scss';

import { useGetWorksitesList } from '~/hooks/worksites';
import { IWorksite } from '~/types/worksites';

const columns = [{
  header: 'Nom du chantier',
  accessorKey: 'estimate.worksite.name',
}, {
  header: 'Nom de l\'entreprise',
  accessorKey: 'client.name',
}, {
  header: 'Agence',
  accessorKey: 'agency.name',
}, {
  header: 'Département',
  accessorKey: 'estimate.worksite.department',
}, {
  header: 'Ville',
  accessorKey: 'estimate.worksite.city',
}, {
  header: 'Phase en cours',
  accessorKey: 'activePhase.endDate',
  cell: (d: any) => d.row.original?.activePhase?.name,
}];

const columnsAgency = [{
  header: 'Nom du chantier',
  accessorKey: 'estimate.worksite.name',
}, {
  header: 'Nom de l\'entreprise',
  accessorKey: 'client.name',
}, {
  header: 'Département',
  accessorKey: 'estimate.worksite.department',
}, {
  header: 'Ville',
  accessorKey: 'estimate.worksite.city',
}, {
  header: 'Phase en cours',
  accessorKey: 'activePhase.endDate',
  cell: (d: any) => d.row.original?.activePhase?.name,
}];

const WorksitesTable = (
  {
    worksites,
    handleSorting,
    isAgency,
    baseTo = '/worksites',
  } : {
    worksites: IWorksite[],
    handleSorting?: any,
    baseTo?: string,
    isAgency?: boolean,
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  const { data: list = {} } = useGetWorksitesList();
  const { status = [] } = list;

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0] || null);
  }, [sorting]);

  const data = useMemo(() => worksites, [worksites]);
  const table = useReactTable({
    data,
    columns: isAgency ? columnsAgency : columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IWorksite>) => {
    navigate(`${baseTo}/${row.original._id}`);
  };

  return (
    <div className={styles.worksitesTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                 <p>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {typeof header.column.getIsSorted() === 'string' ? (
                    {
                      asc: <IoChevronUpOutline />,
                      desc: <IoChevronDownOutline />,
                    }[header.column.getIsSorted() as string]
                  ) : (
                    <span className={styles.sortIcons}>
                      <IoChevronUpOutline />
                      <IoChevronDownOutline />
                    </span>
                  )}
                 </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div className={`${styles.cell} ${styles[cell.column.id]}`}>
                    <div className={styles.content}>
                      {cell.column.id === 'status' ? (
                        <>
                          <span>
                            {flexRender(
                              cell.column.columnDef.cell,
                              {
                                ...cell.getContext(),
                                status,
                              },
                            )}
                          </span>
                        </>
                      ) : flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </div>
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WorksitesTable;
